<template>
  <div class="gallery-container">
    <b-row>
      <b-col cols="8">
        <h2>Photo Gallery</h2>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <div>
              TODO: Number of pictures here
          </div>
          <div class="ml-1">
            <b-button variant="primary">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Add</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="gallery-image-wrapper">
      <b-card-group>
        <!-- v-for here -->
        <b-card no-body></b-card>
      </b-card-group>

      <div class="gallery-images"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {},
});
</script>
<style scoped>
.gallery-container {
}
</style>
